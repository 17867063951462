import { gql } from '@apollo/client'

const getStoreQuery = gql`
  query getStore @client {
    mediaStore {
      filterOption
      medium
    }
    scenarioStore {
      filterOption
    }
    traineeStore {
      filterOption
    }
  }
`

const getNotifications = gql`
  query getNotifications @client {
    notifications
  }
`

export { getStoreQuery, getNotifications }
