import React, { Component } from 'react'
import { ErrorBoundary } from '@appsignal/react'
import appsignal from '../utils/appsignal'
import ErrorPage from '../components/ErrorPage'

const withAppsignal = (ChildComponent) => {
  class WithAppsignal extends Component {
    render() {
      return (
        <ErrorBoundary
          instance={appsignal}
          fallback={() => <ErrorPage />}
        >
          <ChildComponent {...this.props} />
        </ErrorBoundary>
      )
    }
  }
  return WithAppsignal
}

export default withAppsignal
