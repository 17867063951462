import React, { Component } from 'react'
import fetch from 'cross-fetch'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  ApolloLink,
  from,
} from '@apollo/client'
import cache from '../apollo/cache'
import typeDefs from '../apollo/typeDefs'
import withAppsignal from './withAppsignal'
import appsignal from '../utils/appsignal'

import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { getCsrfToken } from '../utils/helpers/graphql'
if (['test', 'development'].includes(process.env.NODE_ENV)) {
  // Adds messages only in a test environment
  loadDevMessages()
  loadErrorMessages()
}

const httpLink = new HttpLink({ uri: '/api/graphql', fetch })

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-CSRF-Token': getCsrfToken(),
    },
  })
  return forward(operation)
})

const appsignalBreadcrumb = new ApolloLink((operation, forward) => {
  if (operation.operationName) {
    appsignal.addBreadcrumb({
      category: 'GraphQL',
      action: 'request',
      metadata: {
        operationName: operation.operationName,
        variables: operation.variables,
      },
    })
  }

  return forward(operation)
})

const client = new ApolloClient({
  link: from([authMiddleware, appsignalBreadcrumb, httpLink]),
  cache,
  typeDefs,
  fetchOptions: {
    credentials: 'same-origin',
  },
})

const withApollo = (ChildComponent) => {
  class WithApollo extends Component {
    render() {
      return (
        <ApolloProvider client={client}>
          <ChildComponent client={client} {...this.props} />
        </ApolloProvider>
      )
    }
  }
  return withAppsignal(WithApollo)
}

export default withApollo
