import { gql } from '@apollo/client'

const typeDefs = gql`
  type Store
  type Array
  type String

  type Sort {
    isAscending: Boolean!
    value: String
  }

  extend type Query {
    store: Store
    showTable: Boolean!
    filteredColumns: Array
    filterOption: String
    sortOn: Sort
    medium: Store
  }
`
export default typeDefs
