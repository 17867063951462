import { gql } from '@apollo/client'

const getUserSessionQuery = gql`
  query session($verificationCode: String!) {
    session(verificationCode: $verificationCode) {
      code
      state
      user {
        email
        firstname
        lastname
      }
    }
  }
`

const getLmsSessionQuery = gql`
  query session($id: String!) {
    session(id: $id) {
      code
      verificationCode
      state
      scenario {
        name
        imageUrl
        starsForCompletion
        client {
          name
          imageUrl
        }
      }
      lmsName
      lmsType
      stars
      attempts {
        id
        stars
        finished
      }
    }
  }
`

const updateLmsSessionMutation = gql`
  mutation updateSession($id: String!, $event: SessionEvent!) {
    updateSession(id: $id, event: $event) {
      session {
        code
        verificationCode
        state
        scenario {
          name
          imageUrl
          starsForCompletion
          client {
            name
            imageUrl
          }
        }
        lmsName
        lmsType
        stars
        attempts {
          id
          stars
          finished
        }
      }
    }
  }
`

const getSignInSecretCodeQuery = gql`
  query Session($signinLinkSecret: String!) {
    session(signinLinkSecret: $signinLinkSecret) {
      code
    }
  }
`

const getPublicLinkSessionQuery = gql`
  query publicLink($publicLinkSecret: String!) {
    publicLink(publicLinkSecret: $publicLinkSecret) {
      client {
        name
        description
        primaryColor
        secondaryColor
        contactEmail
        imageUrl
      }
      courses {
        client {
          name
          description
          primaryColor
          secondaryColor
          contactEmail
          imageUrl
        }
        description
        name
        scenarios {
          client {
            name
            description
            primaryColor
            secondaryColor
            contactEmail
            imageUrl
          }
          description
          imageUrl
          name
        }
      }
      scenarios {
        client {
          name
          description
          primaryColor
          secondaryColor
          contactEmail
          imageUrl
        }
        description
        imageUrl
        name
      }
      securityType
      securityDomains
      anonymousSession {
        verificationCode
      }
    }
  }
`

const startPublicLinkSessionMutation = gql`
  mutation StartSession(
    $email: String
    $publicLinkSecret: String!
    $locale: Locale!
  ) {
    startSession(
      email: $email
      publicLinkSecret: $publicLinkSecret
      locale: $locale
    ) {
      success
    }
  }
`

const sendPlayMailMutation = gql`
  mutation SendMail($email: String, $locale: Locale!) {
    startSession(email: $email, locale: $locale) {
      success
    }
  }
`

const createSessionMutation = gql`
  mutation CreateSession($userId: ID!, $email: Boolean!) {
    createSession(userId: $userId, email: $email) {
      session {
        code
        state
        user {
          email
        }
      }
    }
  }
`

export {
  getUserSessionQuery,
  getLmsSessionQuery,
  updateLmsSessionMutation,
  getSignInSecretCodeQuery,
  getPublicLinkSessionQuery,
  sendPlayMailMutation,
  startPublicLinkSessionMutation,
  createSessionMutation,
}
