import { InMemoryCache } from '@apollo/client'
import { getStoreQuery, getNotifications } from './query/store'

const cache = new InMemoryCache({
  typePolicies: {
    Medium: {
      fields: {
        linkedToScenes: {
          merge: false,
        },
      },
    },
    Query: {
      fields: {
        media: { merge: false },
        currentClient: { merge: false },
        currentUser: { merge: false },
        notifications: { merge: false },
        devices: { merge: false },
        users: {
          merge: false,
        },
      },
    },
    Group: {
      fields: {
        courses: { merge: false },
        publishedScenarios: { merge: false },
        userIds: { merge: false },
      },
    },
  },
})

const writeInitialQueries = () => {
  cache.writeQuery({
    query: getStoreQuery,
    data: {
      mediaStore: {
        filterOption: '',
        medium: null,
      },
      scenarioStore: {
        filterOption: '',
      },
      traineeStore: {
        filterOption: '',
      },
    },
  })

  cache.writeQuery({
    query: getNotifications,
    data: {
      notifications: [],
    },
  })
}

writeInitialQueries()

export const resetCache = () => {
  return new Promise(async (resolve) => {
    writeInitialQueries()
    resolve()
  })
}

export default cache
