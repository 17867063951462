import React from 'react'
import { FaCarCrash } from 'react-icons/fa'

const ErrorPage = () => (
  <div
    id="error"
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }}>
    <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
      <span className="text-center">
        <span className="o-table__empty--icon">
          <FaCarCrash />
        </span>
        <h2 className="text-bold text-dark">
          Warp Studio experienced a crash
        </h2>
        <span className="text-normal text-stable-dark">
          Our team has been notified and will investigate the issue.
        </span>
        <hr />
        <span className="text-normal text-stable-dark text-underline cursor-pointer" onClick={() => window.$crisp.push(["do", "chat:open"])}>
          Feel free to send us a message to get more information.
        </span>
      </span>
    </div>
  </div>
)

export default ErrorPage
