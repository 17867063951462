import cache from '../../apollo/cache'
import { getNotifications, getStoreQuery } from '../../apollo/query/store'

const updateStore = (store, newValue) => {
  const stores = cache.readQuery({ query: getStoreQuery })
  const newStore = { ...stores, mediaStore: { ...store, ...newValue } }
  cache.writeQuery({
    query: getStoreQuery,
    data: newStore,
  })
}

const updateScenarioStore = (store, newValue) => {
  const stores = cache.readQuery({ query: getStoreQuery })
  const newStore = { ...stores, scenarioStore: { ...store, ...newValue } }
  cache.writeQuery({
    query: getStoreQuery,
    data: newStore,
  })
}

const updateNotifications = (newValue) => {
  cache.writeQuery({
    query: getNotifications,
    data: {
      notifications: newValue,
    },
  })
}

const getCsrfToken = () =>
  document.querySelector('meta[name=csrf-token]').getAttribute('content')

export { updateScenarioStore, updateStore, updateNotifications, getCsrfToken }
